<template>
  <section id="feather-icons">
    <div class="icon-search-wrapper my-3 mx-auto">
      <b-form-group>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="icons-search"
            v-model="seachQuery"
            placeholder="Search Icons..."
          />
        </b-input-group>
      </b-form-group>
    </div>
    <div
      id="icons-container"
      class="d-flex flex-wrap"
    >
      <b-card
        v-for="icon in filterIcons"
        :key="icon"
        v-b-tooltip.hover.top="icon"
        class="icon-card cursor-pointer text-center mb-2 mx-50"
        @click="copyIconName(icon)"
      >
        <div class="icon-wrapper">
          <tabler-icon
            :icon="icon"
            size="24"
          />
        </div>
        <b-card-text class="icon-name text-truncate mb-0 mt-1">
          {{ icon }}
        </b-card-text>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BCard, BCardText, VBTooltip,
} from 'bootstrap-vue'
import { useClipboard } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BCardText,
    BInputGroupPrepend,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      seachQuery: '',
      icons: [
        "ABIcon",
        "LockOpenIcon",
        "AccessibleIcon",
        "ActivityIcon",
        "AdIcon",
        "AdjustmentsAltIcon",
        "AdjustmentsHorizontalIcon",
        "AdjustmentsIcon",
        "AlarmIcon",
        "AlertCircleIcon",
        "AlertTriangleIcon",
        "AlienIcon",
        "AlignCenterIcon",
        "AlignJustifiedIcon",
        "AlignLeftIcon",
        "AlignRightIcon",
        "AmbulanceIcon",
        "AnchorIcon",
        "ApertureIcon",
        "AppsIcon",
        "ArchiveIcon",
        "ArrowBackIcon",
        "ArrowBackUpIcon",
        "ArrowBarDownIcon",
        "ArrowBarLeftIcon",
        "ArrowBarRightIcon",
        "ArrowBarToDownIcon",
        "ArrowBarToLeftIcon",
        "ArrowBarToRightIcon",
        "ArrowBarToUpIcon",
        "ArrowBarUpIcon",
        "ArrowDownCircleIcon",
        "ArrowDownIcon",
        "ArrowDownLeftCircleIcon",
        "ArrowDownLeftIcon",
        "ArrowDownRightCircleIcon",
        "ArrowDownRightIcon",
        "ArrowForwardIcon",
        "ArrowForwardUpIcon",
        "ArrowLeftCircleIcon",
        "ArrowLeftIcon",
        "ArrowNarrowDownIcon",
        "ArrowNarrowLeftIcon",
        "ArrowNarrowRightIcon",
        "ArrowNarrowUpIcon",
        "ArrowRightCircleIcon",
        "ArrowRightIcon",
        "ArrowUpCircleIcon",
        "ArrowUpIcon",
        "ArrowUpLeftCircleIcon",
        "ArrowUpLeftIcon",
        "ArrowUpRightCircleIcon",
        "ArrowUpRightIcon",
        "ArrowsDiagonal2Icon",
        "ArrowsDiagonalIcon",
        "ArrowsHorizontalIcon",
        "ArrowsMaximizeIcon",
        "ArrowsMinimizeIcon",
        "ArrowsSortIcon",
        "ArrowsVerticalIcon",
        "ArtboardIcon",
        "AtIcon",
        "Atom2Icon",
        "AtomIcon",
        "AwardIcon",
        "BackspaceIcon",
        "BallBasketballIcon",
        "BallBowlingIcon",
        "BallTennisIcon",
        "BallVolleyballIcon",
        "BanIcon",
        "BandageIcon",
        "BarcodeIcon",
        "BasketIcon",
        "Battery1Icon",
        "Battery2Icon",
        "Battery3Icon",
        "Battery4Icon",
        "BatteryChargingIcon",
        "BatteryIcon",
        "BedIcon",
        "BellIcon",
        "BikeIcon",
        "BluetoothIcon",
        "BoldIcon",
        "BoltIcon",
        "BookIcon",
        "BookmarkIcon",
        "BorderAllIcon",
        "BorderBottomIcon",
        "BorderHorizontalIcon",
        "BorderInnerIcon",
        "BorderLeftIcon",
        "BorderNoneIcon",
        "BorderOuterIcon",
        "BorderRadiusIcon",
        "BorderRightIcon",
        "BorderTopIcon",
        "BorderVerticalIcon",
        "BoxIcon",
        "BracesIcon",
        "BracketsIcon",
        "BrandAndroidIcon",
        "BrandAppleIcon",
        "BrandBehanceIcon",
        "BrandChromeIcon",
        "BrandCodepenIcon",
        "BrandDribbbleIcon",
        "BrandFacebookIcon",
        "BrandFigmaIcon",
        "BrandFramerIcon",
        "BrandGithubIcon",
        "BrandGitlabIcon",
        "BrandGoogleDriveIcon",
        "BrandGoogleIcon",
        "BrandInstagramIcon",
        "BrandLinkedinIcon",
        "BrandMediumIcon",
        "BrandMessengerIcon",
        "BrandOperaIcon",
        "BrandPaypalIcon",
        "BrandPinterestIcon",
        "BrandRedditIcon",
        "BrandSafariIcon",
        "BrandSketchIcon",
        "BrandSlackIcon",
        "BrandSnapchatIcon",
        "BrandTablerIcon",
        "BrandTailwindIcon",
        "BrandTelegramIcon",
        "BrandTiktokIcon",
        "BrandTwitterIcon",
        "BrandWhatsappIcon",
        "BrandYoutubeIcon",
        "BriefcaseIcon",
        "BrightnessDownIcon",
        "BrightnessIcon",
        "BrightnessUpIcon",
        "BrowserIcon",
        "BrushIcon",
        "BucketIcon",
        "BugIcon",
        "BuildingArchIcon",
        "BuildingBankIcon",
        "BuildingBridge2Icon",
        "BuildingBridgeIcon",
        "BuildingChurchIcon",
        "BuildingCommunityIcon",
        "BuildingHospitalIcon",
        "BuildingIcon",
        "BuildingPavilonIcon",
        "BuildingSkyscraperIcon",
        "BuildingStoreIcon",
        "BuildingWarehouseIcon",
        "BulbIcon",
        "BulbOffIcon",
        "BusIcon",
        "CalculatorIcon",
        "CalendarEventIcon",
        "CalendarIcon",
        "CalendarMinusIcon",
        "CalendarPlusIcon",
        "CameraIcon",
        "CameraMinusIcon",
        "CameraPlusIcon",
        "CaptureIcon",
        "CarIcon",
        "CaravanIcon",
        "CaretDownIcon",
        "CaretLeftIcon",
        "CaretRightIcon",
        "CaretUpIcon",
        "CashIcon",
        "CastIcon",
        "ChartAreaIcon",
        "ChartAreaLineIcon",
        "ChartBarIcon",
        "ChartBubbleIcon",
        "ChartCandleIcon",
        "ChartDonutIcon",
        "ChartLineIcon",
        "ChartPieIcon",
        "CheckIcon",
        "CheckboxIcon",
        "ChecksIcon",
        "ChevronDownIcon",
        "ChevronLeftIcon",
        "ChevronRightIcon",
        "ChevronUpIcon",
        "ChevronsDownIcon",
        "ChevronsLeftIcon",
        "ChevronsRightIcon",
        "ChevronsUpIcon",
        "CircleCheckIcon",
        "CircleIcon",
        "CircleMinusIcon",
        "CirclePlusIcon",
        "CircleXIcon",
        "ClearFormattingIcon",
        "ClickIcon",
        "ClipboardCheckIcon",
        "ClipboardIcon",
        "ClipboardListIcon",
        "ClipboardXIcon",
        "ClockIcon",
        "CloudDownloadIcon",
        "CloudIcon",
        "CloudRainIcon",
        "CloudSnowIcon",
        "CloudStormIcon",
        "CloudUploadIcon",
        "CodeIcon",
        "CoinIcon",
        "ColorPickerIcon",
        "ColorSwatchIcon",
        "ColumnsIcon",
        "CometIcon",
        "CommandIcon",
        "CompassIcon",
        "ContrastIcon",
        "CopyIcon",
        "CopyleftIcon",
        "CopyrightIcon",
        "CornerDownLeftIcon",
        "CornerDownRightIcon",
        "CornerLeftDownIcon",
        "CornerLeftUpIcon",
        "CornerRightDownIcon",
        "CornerRightUpIcon",
        "CornerUpLeftIcon",
        "CornerUpRightIcon",
        "CreditCardIcon",
        "CropIcon",
        "CrosshairIcon",
        "CurrencyBitcoinIcon",
        "CurrencyDollarIcon",
        "CurrencyEuroIcon",
        "CurrencyPoundIcon",
        "CurrencyRupeeIcon",
        "CurrencyYenIcon",
        "CutIcon",
        "DashboardIcon",
        "DatabaseIcon",
        "DeviceDesktopIcon",
        "DeviceFloppyIcon",
        "DeviceGamepadIcon",
        "DeviceLaptopIcon",
        "DeviceMobileIcon",
        "DeviceMobileVibrationIcon",
        "DeviceSpeakerIcon",
        "DeviceTabletIcon",
        "DeviceTvIcon",
        "DeviceWatchIcon",
        "DevicesIcon",
        "DiamondIcon",
        "DiceIcon",
        "DirectionHorizontalIcon",
        "DirectionIcon",
        "DirectionsIcon",
        "Disabled2Icon",
        "DisabledIcon",
        "DiscIcon",
        "DiscountIcon",
        "DotsCircleHorizontalIcon",
        "DotsDiagonal2Icon",
        "DotsDiagonalIcon",
        "DotsIcon",
        "DotsVerticalIcon",
        "DownloadIcon",
        "DragDrop2Icon",
        "DragDropIcon",
        "DropletIcon",
        "EarIcon",
        "EditIcon",
        "EggIcon",
        "ElectricScooterIcon",
        "EmphasisIcon",
        "EraserIcon",
        "ExchangeIcon",
        "ExposureIcon",
        "ExternalLinkIcon",
        "EyeIcon",
        "FaceIdIcon",
        "FileCheckIcon",
        "FileCodeIcon",
        "FileDownloadIcon",
        "FileHorizontalIcon",
        "FileIcon",
        "FileInvoiceIcon",
        "FileMinusIcon",
        "FileMusicIcon",
        "FilePlusIcon",
        "FileShredderIcon",
        "FileTextIcon",
        "FileUploadIcon",
        "FileXIcon",
        "FilterIcon",
        "FingerprintIcon",
        "FiretruckIcon",
        "FlagIcon",
        "FlameIcon",
        "FlaskIcon",
        "FlipHorizontalIcon",
        "FlipVerticalIcon",
        "FloatCenterIcon",
        "FloatLeftIcon",
        "FloatRightIcon",
        "Focus2Icon",
        "FocusIcon",
        "FolderIcon",
        "FolderMinusIcon",
        "FolderPlusIcon",
        "FolderXIcon",
        "FoldersIcon",
        "Forbid2Icon",
        "ForbidIcon",
        "ForkliftIcon",
        "FrameIcon",
        "FriendsIcon",
        "GasStationIcon",
        "GaugeIcon",
        "GhostIcon",
        "GiftIcon",
        "GitBranchIcon",
        "GitCommitIcon",
        "GitCompareIcon",
        "GitForkIcon",
        "GitMergeIcon",
        "GitPullRequestIcon",
        "GlassFullIcon",
        "GlassIcon",
        "GlobeIcon",
        "GridDotsIcon",
        "GridIcon",
        "GripHorizontalIcon",
        "GripVerticalIcon",
        "H1Icon",
        "H2Icon",
        "H3Icon",
        "H4Icon",
        "H5Icon",
        "H6Icon",
        "HandMiddleFingerIcon",
        "HandStopIcon",
        "HashIcon",
        "HeadphonesIcon",
        "HeadsetIcon",
        "HeartIcon",
        "HelpIcon",
        "HexagonIcon",
        "HistoryIcon",
        "Home2Icon",
        "HomeIcon",
        "IceCreamIcon",
        "IdIcon",
        "InboxIcon",
        "IndentDecreaseIcon",
        "IndentIncreaseIcon",
        "InfinityIcon",
        "InfoCircleIcon",
        "InfoSquareIcon",
        "ItalicIcon",
        "KeyIcon",
        "KeyboardHideIcon",
        "KeyboardIcon",
        "KeyboardShowIcon",
        "LanguageIcon",
        "LayersDifferenceIcon",
        "LayersIntersectIcon",
        "LayersSubtractIcon",
        "LayersUnionIcon",
        "Layout2Icon",
        "LayoutAlignBottomIcon",
        "LayoutAlignCenterIcon",
        "LayoutAlignLeftIcon",
        "LayoutAlignMiddleIcon",
        "LayoutAlignRightIcon",
        "LayoutAlignTopIcon",
        "LayoutBottombarIcon",
        "LayoutCardsIcon",
        "LayoutColumnsIcon",
        "LayoutDistributeHorizontalIcon",
        "LayoutDistributeVerticalIcon",
        "LayoutIcon",
        "LayoutKanbanIcon",
        "LayoutListIcon",
        "LayoutNavbarIcon",
        "LayoutRowsIcon",
        "LayoutSidebarIcon",
        "LayoutSidebarRightIcon",
        "LegoIcon",
        "LetterAIcon",
        "LetterBIcon",
        "LetterCIcon",
        "LetterDIcon",
        "LetterEIcon",
        "LetterFIcon",
        "LetterGIcon",
        "LetterHIcon",
        "LetterIIcon",
        "LetterJIcon",
        "LetterKIcon",
        "LetterLIcon",
        "LetterMIcon",
        "LetterNIcon",
        "LetterOIcon",
        "LetterPIcon",
        "LetterQIcon",
        "LetterRIcon",
        "LetterSIcon",
        "LetterTIcon",
        "LetterUIcon",
        "LetterVIcon",
        "LetterWIcon",
        "LetterXIcon",
        "LetterYIcon",
        "LetterZIcon",
        "LettersCaseIcon",
        "LicenseIcon",
        "LifebuoyIcon",
        "LineHeightIcon",
        "LineIcon",
        "LinkIcon",
        "ListCheckIcon",
        "ListIcon",
        "LivePhotoIcon",
        "LiveViewIcon",
        "LoaderIcon",
        "LoaderQuarterIcon",
        "LocationIcon",
        "LockIcon",
        "LockOpenIcon",
        "LoginIcon",
        "LogoutIcon",
        "MagnetIcon",
        "MailIcon",
        "MailOpenedIcon",
        "ManIcon",
        "Map2Icon",
        "MapIcon",
        "MapPinIcon",
        "MarkdownIcon",
        "MarqueeIcon",
        "MarsIcon",
        "MathIcon",
        "MaximizeIcon",
        "MedalIcon",
        "MedicalCrossIcon",
        "Menu2Icon",
        "MenuIcon",
        "Message2Icon",
        "MessageCircleIcon",
        "MessageDotsIcon",
        "MessageIcon",
        "MessagePlusIcon",
        "MessageReportIcon",
        "MessagesIcon",
        "MicrophoneIcon",
        "MinimizeIcon",
        "MinusIcon",
        "MistIcon",
        "MoodConfuzedIcon",
        "MoodHappyIcon",
        "MoodKidIcon",
        "MoodNeutralIcon",
        "MoodSadIcon",
        "MoodSmileIcon",
        "MoodSuprisedIcon",
        "MoodTongueIcon",
        "MoonIcon",
        "MouseIcon",
        "MovieIcon",
        "MugIcon",
        "MusicIcon",
        "NewSectionIcon",
        "NewsIcon",
        "NoteIcon",
        "NotebookIcon",
        "NotesIcon",
        "NotificationIcon",
        "OmegaIcon",
        "OutletIcon",
        "PackageIcon",
        "PageBreakIcon",
        "PaintIcon",
        "PaletteIcon",
        "PaperclipIcon",
        "ParenthesesIcon",
        "ParkingIcon",
        "PencilIcon",
        "PhoneCallIcon",
        "PhoneCallingIcon",
        "PhoneCheckIcon",
        "PhoneIcon",
        "PhoneIncomingIcon",
        "PhoneOutgoingIcon",
        "PhonePauseIcon",
        "PhonePlusIcon",
        "PhoneXIcon",
        "PhotoIcon",
        "PillIcon",
        "PinIcon",
        "PlaneArrivalIcon",
        "PlaneDepartureIcon",
        "PlaneIcon",
        "PlanetIcon",
        "PlugIcon",
        "PlusIcon",
        "PointIcon",
        "PowerIcon",
        "PresentationIcon",
        "PrinterIcon",
        "PromptIcon",
        "PuzzleIcon",
        "QrcodeIcon",
        "QuestionMarkIcon",
        "RecordMailIcon",
        "RecycleIcon",
        "RefreshIcon",
        "RegisteredIcon",
        "RepeatIcon",
        "RepeatOnceIcon",
        "ReplaceIcon",
        "RocketIcon",
        "Rotate2Icon",
        "RotateClockwise2Icon",
        "RotateClockwiseIcon",
        "RotateIcon",
        "RotateRectangleIcon",
        "RouteIcon",
        "RouterIcon",
        "RssIcon",
        "RulerIcon",
        "RunIcon",
        "SailboatIcon",
        "ScaleIcon",
        "ScanIcon",
        "ScissorsIcon",
        "ScooterIcon",
        "SearchIcon",
        "SelectIcon",
        "SelectorIcon",
        "SendIcon",
        "SeparatorHorizontalIcon",
        "SeparatorIcon",
        "SeparatorVerticalIcon",
        "ServerIcon",
        "ServicemarkIcon",
        "SettingsIcon",
        "ShapeIcon",
        "ShareIcon",
        "ShieldCheckIcon",
        "ShieldIcon",
        "ShieldXIcon",
        "ShipIcon",
        "ShirtIcon",
        "ShoppingCartIcon",
        "SitemapIcon",
        "SliceIcon",
        "SlideshowIcon",
        "SnowflakeIcon",
        "SocialIcon",
        "SortAscendingIcon",
        "SortDescendingIcon",
        "SpaceIcon",
        "SquareCheckIcon",
        "SquareIcon",
        "SquareMinusIcon",
        "SquarePlusIcon",
        "SquareXIcon",
        "StackIcon",
        "StairsDownIcon",
        "StairsIcon",
        "StairsUpIcon",
        "StarIcon",
        "SteeringWheelIcon",
        "StickerIcon",
        "StrikethroughIcon",
        "SubscriptIcon",
        "SubtaskIcon",
        "SumIcon",
        "SunIcon",
        "SunsetIcon",
        "SunshineIcon",
        "SuperscriptIcon",
        "SwimmingIcon",
        "SwitchHorizontalIcon",
        "SwitchIcon",
        "SwitchVerticalIcon",
        "TableIcon",
        "TagIcon",
        "Tallymark1Icon",
        "Tallymark2Icon",
        "Tallymark3Icon",
        "Tallymark4Icon",
        "TallymarksIcon",
        "TargetIcon",
        "TaxIcon",
        "TemperatureCelsiusIcon",
        "TemperatureFahrenheitIcon",
        "TemperatureIcon",
        "TemperatureMinusIcon",
        "TemperaturePlusIcon",
        "TemplateIcon",
        "Terminal2Icon",
        "TerminalIcon",
        "TestPipeIcon",
        "TextWrapDisabledIcon",
        "TextWrapIcon",
        "ThumbDownIcon",
        "ThumbUpIcon",
        "TicketIcon",
        "TirIcon",
        "ToggleLeftIcon",
        "ToggleRightIcon",
        "ToolIcon",
        "ToolsIcon",
        "TractorIcon",
        "TrademarkIcon",
        "TrafficConeIcon",
        "TrashIcon",
        "TreesIcon",
        "TrendingDownIcon",
        "TrendingUpIcon",
        "TriangleIcon",
        "TrophyIcon",
        "TruckDeliveryIcon",
        "TruckIcon",
        "TruckReturnIcon",
        "TwoFactorAuthIcon",
        "TypographyIcon",
        "UmbrellaIcon",
        "UnderlineIcon",
        "UnlinkIcon",
        "UploadIcon",
        "UrgentIcon",
        "UserCheckIcon",
        "UserExclamationIcon",
        "UserIcon",
        "UserMinusIcon",
        "UserPlusIcon",
        "UserXIcon",
        "UsersIcon",
        "VectorIcon",
        "VectorTriangleIcon",
        "VenusIcon",
        "ViewfinderIcon",
        "ViewportNarrowIcon",
        "ViewportWideIcon",
        "VirusIcon",
        "Volume2Icon",
        "Volume3Icon",
        "VolumeIcon",
        "WalkIcon",
        "WalletIcon",
        "WandIcon",
        "Wifi0Icon",
        "Wifi1Icon",
        "Wifi2Icon",
        "WifiIcon",
        "WindIcon",
        "WiperIcon",
        "WiperWashIcon",
        "WomanIcon",
        "WorldIcon",
        "XIcon",
        "YinYangIcon",
        "ZodiacAquariusIcon",
        "ZodiacAriesIcon",
        "ZodiacCancerIcon",
        "ZodiacCapricornIcon",
        "ZodiacGeminiIcon",
        "ZodiacLeoIcon",
        "ZodiacLibraIcon",
        "ZodiacPiscesIcon",
        "ZodiacSagittariusIcon",
        "ZodiacScorpioIcon",
        "ZodiacTaurusIcon",
        "ZodiacVirgoIcon",
        "ZoomCancelIcon",
        "ZoomInIcon",
        "ZoomOutIcon"
      ],
    }
  },
  computed: {
    filterIcons() {
      const seachQueryIcon = this.seachQuery.toLowerCase()
      return this.icons.filter(item => item.toLowerCase().includes(seachQueryIcon))
    },
  },
  setup() {
    const toast = useToast()
    const { copy } = useClipboard()

    const copyIconName = iconName => {
      copy(iconName)

      toast({
        component: ToastificationContent,
        props: {
          title: 'Icon name copied',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    }

    return {
      copyIconName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
